<template>
  <div>
    <section-header title="Music Academy"></section-header>
    <div class="d-md-flex">
      <div class="mx-2 my-4 text-justify">
        Besides being an assiduous contributor to Bengali music, Goutam Ghosal has also established himself as one of the leading vocal music trainers in Kolkata. He, along with his musical wife Shiuli Ghosal, runs a prestigious music school named Meghpakhi Academy of Music. Since founded in the year 2000, this academy has been producing plenteous talented students who have established themselves as significant singers in the music industry.
        <br /> <br />
        At Meghpakhi Academy of Music, separate classes are conducted for novice, intermediate and advanced learners. As this academy does not believe in the group training system, students are trained with individual attention. Classes for juniors mainly occur on the weekends. For the seniors, classes are held all over the week. For long-distance learners online training classes are also conducted.
        <br /> <br />
        Meghpakhi Academy of Music is situated at the residential place of Goutam Ghosal, Mukundapur, Kolkata. The online application form is also available.
      </div>
      <div class="ma-4 text-center">
        <iframe :width="$vuetify.breakpoint.mdAndUp ? 400 : 360" :height="$vuetify.breakpoint.mdAndUp ? 360 : 280"
          style="border:0" loading="lazy" allowfullscreen referrerpolicy="no-referrer-when-downgrade"
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyC-1Myj6wPoTWusKv16Audx20AZYVyoT-c&q=Meghpakhi+Vocal+Music+Training+Academy">
        </iframe>
      </div>
    </div>
    <div class="text-center mt-3">
      <v-btn rounded class="ma-2" color="secondary" href="//forms.gle/j5uYF9JbVUdbYA4d6" target="_blank">Apply for
        Admission</v-btn>
      <v-btn rounded class="mx-2" color="primary darken-3" to="academy" target="_blank">Know More</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  components: {
    SectionHeader: () => import('@/components/reusable/SectionHeader')
  }
}
</script>
